.active {
  color: #000 !important;
  font-weight: normal;
}

.small-text {
  font-size: 1em;
}

.line {
  background-color:#c3c3c3; 
  font-size: 1px;
}

.max-600 {
  max-width: 600;
  margin: auto
}

.navbar-light {
  background-color: #dcdcdc !important;
}

.navbar {
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}

.navbar-light .navbar-nav .nav-link
 {
    color: #000;
    font-weight: 600;
    font-size: 1.2em;
 }